import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { ECOM_APP_DEF_ID } from './components/app';
import { FedopsInteractions } from './editor-app/constants';
import { addWishlistPageForPluginReAddition } from './editor-app/events/addWishlistPageForPluginReAddition';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI
): Promise<void> => {
  const { firstInstall } = options;

  const ecomPublicAPI = await editorSDK.application.getPublicAPI('', {
    appDefinitionId: ECOM_APP_DEF_ID,
  });

  if (!firstInstall) {
    await addWishlistPageForPluginReAddition(editorSDK, ecomPublicAPI);
  }

  if (firstInstall) {
    flowAPI.fedops.interactionStarted(
      FedopsInteractions.WishlistButtonInstallationEditorReady
    );
    // @ts-expect-error: no types for this method
    await ecomPublicAPI.installWishlistApp();
    flowAPI.fedops.interactionEnded(
      FedopsInteractions.WishlistButtonInstallationEditorReady
    );
  }
};
