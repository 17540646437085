import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { wishlistDesignBuilderFn } from '../../editor-app/manifest/global-design/widgetDesignBuilderFn';
import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { initLocaleKeys } from './services/initLocaleKeys';
import { helpId, WishlistButtonStates } from './config/constants';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  controllerBuilder.set({
    displayName: t.productPage.wishlistButton.displayName(),
    nickname: 'firstWidget',
  });

  controllerBuilder
    .configureWidgetViewState(
      WishlistButtonStates.notInWishlist,
      (stateBuilder) => {
        stateBuilder.set({
          initial: true,
          title: t.productPage.wishlistButton.addToWishlist.state.label(),
        });
      },
    )
    .configureWidgetViewState(
      WishlistButtonStates.inWishlist,
      (stateBuilder) => {
        stateBuilder.set({
          title: t.productPage.wishlistButton.removeFromWishlist.state.label(),
        });
      },
    );

  controllerBuilder.configureConnectedComponents(
    'addButton',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.behavior().set({
        closed: {
          selectable: true,
        },
      });
      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', { behavior: 'HIDE' })
        .set('settings', { behavior: 'DEFAULT' });
    },
  );

  controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t.productPage.wishlistButton.gfpp.design(),
      actionType: 'design',
      actionId: '',
    })
    .set('help', { id: helpId })
    .set('design', { behavior: 'DEFAULT' })
    .set('connect', { behavior: 'HIDE' })
    .set('link', { behavior: 'HIDE' })
    .set('add', { behavior: 'HIDE' });

  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) =>
    wishlistDesignBuilderFn(widgetDesignBuilder, t),
  );
};
