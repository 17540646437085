/* eslint-disable */
/* tslint:disable */
export function LocaleKeys<R extends string>(t: (...args: unknown[]) => R) {
  return {
    app: {
      widget: {
        welcome: () => t('app.widget.welcome'), /* Welcome */
      },
      settings: {
        label: () => t('app.settings.label'), /* Configure Widget */
        tabs: {
          main: () => t('app.settings.tabs.main'), /* Main */
          design: () => t('app.settings.tabs.design'), /* Design */
        },
        defaults: {
          greetingsPretext: () => t('app.settings.defaults.greetingsPretext'), /* to */
        },
      },
    },
    productPage: {
      wishlistButton: {
        design: {
          removeFromWishlist: {
            panelHeader: () => t('productPage.wishlistButton.design.removeFromWishlist.panelHeader'), /* Remove from Wishlist */
            sectionName: () => t('productPage.wishlistButton.design.removeFromWishlist.sectionName'), /* Remove from Wishlist */
          },
          panelHeader: () => t('productPage.wishlistButton.design.panelHeader'), /* Wishlist Button Design */
          addToWishist: {
            panelHeader: () => t('productPage.wishlistButton.design.addToWishist.panelHeader'), /* Add to Wishlist */
            sectionName: () => t('productPage.wishlistButton.design.addToWishist.sectionName'), /* Add to Wishlist */
          },
        },
        displayName: () => t('productPage.wishlistButton.displayName'), /* Wishlist Button */
        gfpp: {
          design: () => t('productPage.wishlistButton.gfpp.design'), /* Design Wishlist Button */
        },
        addToWishlist: {
          state: {
            label: () => t('productPage.wishlistButton.addToWishlist.state.label'), /* Add to Wishlist */
          },
        },
        removeFromWishlist: {
          state: {
            label: () => t('productPage.wishlistButton.removeFromWishlist.state.label'), /* Remove from Wishlist */
          },
        },
      },
    },
    wishlist: {
      button: () => t('wishlist.button'), /* Add to Wishlist */
      remove: {
        button: () => t('wishlist.remove.button'), /* Remove from Wishlist */
      },
    },
    sr: {
      wishlist: {
        button: () => t('sr.wishlist.button'), /* Add to Wishlist */
        remove: {
          button: () => t('sr.wishlist.remove.button'), /* Remove from Wishlist */
        },
      },
    },
  };
}

export type ILocaleKeys = ReturnType<typeof LocaleKeys>;
