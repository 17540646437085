import { helpId } from '../../../components/WishlistButton/config/constants';
import { WidgetDesignBuilderFn } from '../../editor.app.types';

export const wishlistDesignBuilderFn: WidgetDesignBuilderFn = (
  widgetDesignBuilder,
  t,
) => {
  widgetDesignBuilder.set({
    title: t.productPage.wishlistButton.design.panelHeader(),
    customHelpId: helpId,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder.addTab((tabBuilder) => {
    tabBuilder
      .set({
        label: t.productPage.wishlistButton.design.addToWishist.sectionName(),
        dependents: ['addButton'],
      })
      .groups()
      .set({
        roles: ['addButton'],
      });
  });

  widgetDesignTabsBuilder.addTab((tabBuilder) => {
    tabBuilder
      .set({
        label:
          t.productPage.wishlistButton.design.removeFromWishlist.sectionName(),
        dependents: ['removeButton'],
      })
      .groups()
      .set({
        roles: ['removeButton'],
      });
  });
};
