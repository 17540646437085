import { EditorSDK } from '@wix/platform-editor-sdk';
import { WISHLIST_BLOCKS } from '@wix/app-definition-ids';

export const addWishlistPageForPluginReAddition = async (
  editorSDK: EditorSDK,
  ecomPublicAPI
) => {
  return editorSDK.addEventListener('componentAddedToStage', async (e) => {
    const { compRef } = e.detail;

    const data = await editorSDK.components.data.get('', {
      componentRef: compRef,
    });
    // @ts-expect-error - no types for this method
    const { appDefinitionId } = data;

    if (appDefinitionId === WISHLIST_BLOCKS) {
      await ecomPublicAPI.installWishlistApp();
    }
  });
};
