export enum WishlistButtonWidgetComponentIds {
  multiStateBox = '#multiStateBox1',
  addButton = '#addButton',
  removeButton = '#removeButton',
}

export enum WishlistButtonStates {
  inWishlist = 'inWishlist',
  notInWishlist = 'notInWishlist',
}

export const helpId = '4ee3f058-5b8f-4706-8cba-a2c705526807';
