
  var editorScriptEntry = require('/home/builduser/work/cc2a08847946417c/packages/wixstores-client-wishlist-button/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/cc2a08847946417c/packages/wixstores-client-wishlist-button/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = {
        DSN: 'https://dd84cdb4b7424f93a4f1cfbe0389bdab@o37417.ingest.sentry.io/4504841825026048',
        id: 'dd84cdb4b7424f93a4f1cfbe0389bdab',
        projectName: 'wishlist-button',
        teamName: 'wixstores',
        errorMonitor: true,
      };
      const experimentsConfig = {"scopes":[],"centralized":true};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/cc2a08847946417c/packages/wixstores-client-wishlist-button/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
      const defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","productPage.wishlistButton.design.removeFromWishlist.panelHeader":"Remove from Wishlist","wishlist.button":"Add to Wishlist","productPage.wishlistButton.design.removeFromWishlist.sectionName":"Remove from Wishlist","sr.wishlist.button":"Add to Wishlist","productPage.wishlistButton.design.panelHeader":"Wishlist Button Design","wishlist.remove.button":"Remove from Wishlist","productPage.wishlistButton.displayName":"Wishlist Button","productPage.wishlistButton.gfpp.design":"Design Wishlist Button","sr.wishlist.remove.button":"Remove from Wishlist","productPage.wishlistButton.design.addToWishist.panelHeader":"Add to Wishlist","productPage.wishlistButton.design.addToWishist.sectionName":"Add to Wishlist","productPage.wishlistButton.addToWishlist.state.label":"Add to Wishlist","productPage.wishlistButton.removeFromWishlist.state.label":"Remove from Wishlist"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'wixstores-client-wishlist-button',
        appDefId: 'a97c0203-062a-4dd5-97cf-a90f9800a13a',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/cc2a08847946417c/packages/wixstores-client-wishlist-button/src/components/WishlistButton/editor.controller.ts');

      const model_0 = require('/home/builduser/work/cc2a08847946417c/packages/wixstores-client-wishlist-button/src/components/WishlistButton/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "a97c0203-062a-4dd5-97cf-a90f9800a13a-e7umd";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"a97c0203-062a-4dd5-97cf-a90f9800a13a-e7umd": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "a97c0203-062a-4dd5-97cf-a90f9800a13a-e7umd", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
